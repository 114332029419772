<ucs-spinner></ucs-spinner>
<div class="page-container position-relative">
  <header id="top" class="ucs-header">
    <div class="container-fluid">
      <div [ngClass]="{'no-menu': !showTopMenuAndFooter}" class="row mb-3">
        <ucs-icon *ngIf="!showTopMenuAndFooter" [shape]="'ucs_logo'" [class]="'white'"></ucs-icon>
        <ucs-top-menu *ngIf="showTopMenuAndFooter" class="flex-fill">
        </ucs-top-menu>
      </div>
    </div>
  </header>

  <ucs-toast-container></ucs-toast-container>
  <div class="container-fluid">
    <ucs-document-missing-message
      *ngIf="showTopMenuAndFooter && userInfo?.accredited === false
          && userInfo?.enabledChannels?.includes('PIA')"></ucs-document-missing-message>
  </div>
  <div class="container-fluid">
    <ucs-dealer-locked-message *ngIf="userInfo?.locked" [lockReason]="userInfo?.lockReason"
                               class="col col-12"></ucs-dealer-locked-message>
  </div>
  <div class="bar-alert-container" *ngIf="isAlertActive">
    <ngb-alert class="px-2 text-center bar-alert" [dismissible]="alert.isDismissible" [type]="alert.type" (closed)="close(alert)">
      {{ alert.message }}
    </ngb-alert>
  </div>
  <div id="ucs-content" class="container-fluid main-content">
    <router-outlet></router-outlet>
  </div>

  <div *ngIf="!(getBrowserName() === 'chrome' || getBrowserName() === 'chromium based')">
    <ucs-browser-alert-dialog></ucs-browser-alert-dialog>
  </div>

  <div *ngIf="stage" class="stage-info"
       [ngClass]="{
     'dev':stage ==='local' || stage === 'dev',
     'qa' :stage === 'qa',
     'demo' :stage === 'demo',
     'prod': stage === 'prod'
     }">
    <h1>{{stage}}</h1>
  </div>

  <footer class="sticky-footer position-absolute">
    <div [ngClass]="{'no-menu': !showTopMenuAndFooter}" class="container-fluid footer">
      <div *ngIf="!showTopMenuAndFooter" class="col-auto text text-big">
        <strong>&copy; UCS {{ currentYear }}</strong></div>
      <ucs-footer *ngIf="showTopMenuAndFooter" class="flex-fill"></ucs-footer>
    </div>
  </footer>
</div>
