import { combine, generateUUID, toServerDuration, relativeToClocks, assign, isNumber } from '@datadog/browser-core';
import { RumPerformanceEntryType } from '../../browser/performanceCollection';
import { matchRequestTiming } from './matchRequestTiming';
import { computePerformanceResourceDetails, computePerformanceResourceDuration, computeResourceKind, computeSize, isRequestKind, isLongDataUrl, sanitizeDataUrl } from './resourceUtils';
export function startResourceCollection(lifeCycle, configuration, sessionManager, pageStateHistory) {
  lifeCycle.subscribe(8 /* LifeCycleEventType.REQUEST_COMPLETED */, function (request) {
    var rawEvent = processRequest(request, configuration, sessionManager, pageStateHistory);
    if (rawEvent) {
      lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, rawEvent);
    }
  });
  lifeCycle.subscribe(0 /* LifeCycleEventType.PERFORMANCE_ENTRIES_COLLECTED */, function (entries) {
    for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
      var entry = entries_1[_i];
      if (entry.entryType === RumPerformanceEntryType.RESOURCE && !isRequestKind(entry)) {
        var rawEvent = processResourceEntry(entry, configuration, sessionManager);
        if (rawEvent) {
          lifeCycle.notify(12 /* LifeCycleEventType.RAW_RUM_EVENT_COLLECTED */, rawEvent);
        }
      }
    }
  });
}
function processRequest(request, configuration, sessionManager, pageStateHistory) {
  var matchingTiming = matchRequestTiming(request);
  var startClocks = matchingTiming ? relativeToClocks(matchingTiming.startTime) : request.startClocks;
  var shouldIndex = shouldIndexResource(configuration, sessionManager, startClocks);
  var tracingInfo = computeRequestTracingInfo(request, configuration);
  if (!shouldIndex && !tracingInfo) {
    return;
  }
  var type = request.type === "xhr" /* RequestType.XHR */ ? "xhr" /* ResourceType.XHR */ : "fetch" /* ResourceType.FETCH */;
  var correspondingTimingOverrides = matchingTiming ? computePerformanceEntryMetrics(matchingTiming) : undefined;
  var duration = computeRequestDuration(pageStateHistory, startClocks, request.duration);
  var resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type: type,
      duration: duration,
      method: request.method,
      status_code: request.status,
      url: isLongDataUrl(request.url) ? sanitizeDataUrl(request.url) : request.url
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !shouldIndex
    }
  }, tracingInfo, correspondingTimingOverrides);
  return {
    startTime: startClocks.relative,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: matchingTiming,
      xhr: request.xhr,
      response: request.response,
      requestInput: request.input,
      requestInit: request.init,
      error: request.error,
      isAborted: request.isAborted
    }
  };
}
function processResourceEntry(entry, configuration, sessionManager) {
  var startClocks = relativeToClocks(entry.startTime);
  var shouldIndex = shouldIndexResource(configuration, sessionManager, startClocks);
  var tracingInfo = computeEntryTracingInfo(entry, configuration);
  if (!shouldIndex && !tracingInfo) {
    return;
  }
  var type = computeResourceKind(entry);
  var entryMetrics = computePerformanceEntryMetrics(entry);
  var resourceEvent = combine({
    date: startClocks.timeStamp,
    resource: {
      id: generateUUID(),
      type: type,
      url: entry.name,
      status_code: discardZeroStatus(entry.responseStatus)
    },
    type: "resource" /* RumEventType.RESOURCE */,
    _dd: {
      discarded: !shouldIndex
    }
  }, tracingInfo, entryMetrics);
  return {
    startTime: startClocks.relative,
    rawRumEvent: resourceEvent,
    domainContext: {
      performanceEntry: entry
    }
  };
}
function shouldIndexResource(configuration, sessionManager, resourceStart) {
  return configuration.trackResources && sessionManager.findTrackedSession(resourceStart.relative);
}
function computePerformanceEntryMetrics(timing) {
  var renderBlockingStatus = timing.renderBlockingStatus;
  return {
    resource: assign({
      duration: computePerformanceResourceDuration(timing),
      render_blocking_status: renderBlockingStatus
    }, computeSize(timing), computePerformanceResourceDetails(timing))
  };
}
function computeRequestTracingInfo(request, configuration) {
  var hasBeenTraced = request.traceSampled && request.traceId && request.spanId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      span_id: request.spanId.toDecimalString(),
      trace_id: request.traceId.toDecimalString(),
      rule_psr: getRulePsr(configuration)
    }
  };
}
function computeEntryTracingInfo(entry, configuration) {
  var hasBeenTraced = entry.traceId;
  if (!hasBeenTraced) {
    return undefined;
  }
  return {
    _dd: {
      trace_id: entry.traceId,
      rule_psr: getRulePsr(configuration)
    }
  };
}
/**
 * @returns number between 0 and 1 which represents trace sample rate
 */
function getRulePsr(configuration) {
  return isNumber(configuration.traceSampleRate) ? configuration.traceSampleRate / 100 : undefined;
}
function computeRequestDuration(pageStateHistory, startClocks, duration) {
  return !pageStateHistory.wasInPageStateDuringPeriod("frozen" /* PageState.FROZEN */, startClocks.relative, duration) ? toServerDuration(duration) : undefined;
}
/**
 * The status is 0 for cross-origin resources without CORS headers, so the status is meaningless, and we shouldn't report it
 * https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming/responseStatus#cross-origin_response_status_codes
 */
function discardZeroStatus(statusCode) {
  return statusCode === 0 ? undefined : statusCode;
}
