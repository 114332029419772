import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from './shared/store/app.reducers';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthenticationService} from './shared/service/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemSettingsService} from './shared/service/system-settings.service';
import {
  getBarAlertState,
  getPageSettingsDistributionChannel,
  getUserLocal,
  getUserState,
} from './shared/store/app.reducers';
import {UpdatePageSettingsChannel, UpdateViewSettings} from './shared/store/offer/offer.actions';
import {UpdateUserInfoAction, UpdateUserLocalAction} from './shared/store/user/user.actions';
import {BarAlert} from './shared/model/bar-alert.model';
import {DismissAlertAction} from './shared/store/alert/bar-alert.actions';
import {AppConfig} from './shared/model/app-config.model';
import {APP_CONFIG} from './shared/misc/inject-tokens';
import * as SalesOfferActions from './shared/store/sales-offer/sales-offer.actions';
import {datadogRum} from '@datadog/browser-rum';
import {Location} from '@angular/common';

@Component({
  selector: 'ucs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public showTopMenuAndFooter = true;
  title = 'Used Car Sales';
  currentYear = new Date().getFullYear();
  userInfo: UserInfoDto;
  currentChannel: DistributionChannel;
  stage: string;
  isMSClarityEnabled = false;
  isAlertActive = false;
  alert: BarAlert;
  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * The store is injected to allow the component to dispatch actions
   * @param {Store<AppState>} store: our single redux app store containing our app state
   * @param router: Angular router
   */
  constructor(private store: Store<fromRoot.AppState>, private router: Router,
              private authenticationService: AuthenticationService,
              private translateService: TranslateService,
              private systemSettingsService: SystemSettingsService,
              @Inject(APP_CONFIG) private config: AppConfig,
              private http: HttpClient, private location: Location) {
    this.translateService = translateService;
  }

  ngOnInit() {

    if (this.config.datadogRumEnabled) {
      this.initializeDatadogRum();
    }

    this.updateUserLocal();

    this.router.events
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(event => { // subscribe to change of route parameters
        if (event instanceof NavigationEnd) {
          if (event.url.endsWith('logout')
            || event.url.endsWith('forbidden')
            || event.url.endsWith('auth')
            || event.url.endsWith('landingpage')
            || event.url.endsWith('auth/failure')) {
            this.showTopMenuAndFooter = false;
          }
        } else if (event instanceof NavigationStart &&
            (event.url.startsWith('/offer#') || event.url.endsWith('/offer'))) {
          this.store.dispatch(new SalesOfferActions.UpdateOffersAction());
        }
      });

    this.updateViewSettingsInStore();
    this.subscribeToUserState();
    this.subscribeToUserLocale();
    this.subscribeToUserInfo();
    this.subscribeToPageSettingsDistributionChannel();
    this.subscribeToBarAlertState();
  }

  private subscribeToUserLocale() {
    this.store.select(getUserLocal)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((local) => this.translateService.use(local));
  }

  private subscribeToBarAlertState() {
    this.store.select(getBarAlertState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(alertState => {
        if (alertState) {
          //BarAlert not needed in maintenance
          this.isAlertActive = this.location.path().startsWith('/maintenance') ? false : alertState.isActive;
          this.alert = alertState.alert;
        }
      });
  }
  private subscribeToPageSettingsDistributionChannel() {
    this.store.select(getPageSettingsDistributionChannel)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(distributionChannel => {
        if (distributionChannel) {
          this.currentChannel = distributionChannel;
        }
      });
  }
  private subscribeToUserInfo() {
    this.store.select(fromRoot.getUserInfo)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userInfo => {
        if (this.authenticationService.isAuthenticated()) {
          if (userInfo) {
            this.setStageInfo();
            this.userInfo = userInfo;
            this.authenticationService.connectWebsockets();
          } else {
            this.store.dispatch(new UpdateUserInfoAction());
          }
        }
      });
  }
  private subscribeToUserState() {
    this.store.select(getUserState)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userState => {
        if (this.authenticationService.isAuthenticated()) {
          if (userState) {

            // init distribution channel in store on first user state update
            const channels = AuthenticationService.getPermittedChannelsToBuyOffers(userState);
            if (channels && channels.length > 0) {
              this.store.dispatch(new UpdatePageSettingsChannel(channels[0]));
            }

            this.isMSClarityEnabled = this.systemSettingsService.isSystemFeatureActivatedForAnyChannelOfState(
              'ENABLE_MS_CLARITY', userState);
            if (this.isMSClarityEnabled) {
              this.loadMSClarityScript();
            }
          }
        }
      });
  }
  private initializeDatadogRum() {
    this.http.get(`${this.config.authenticationUrl}/actuator/info`)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((actuatorInfo: any) => {
        const stage = this.determineStage();
        this.setupDatadogRum(actuatorInfo, stage);
      });
  }
  private determineStage(): string {
    const hostname = window.location.hostname;
    if (hostname.includes('entw') || hostname.includes('dev')) {
      return 'dev';
    } else if (hostname.includes('qa')) {
      return 'qa';
    } else {
      return 'prod';
    }
  }
  private setupDatadogRum(info: any, stage: string) {
    datadogRum.init({
      applicationId: '618f2bb3-4c2b-4fe0-9fef-5ebd497a71ae',
      clientToken: 'pub86519919cc7b95bf0ab6ec684b87613c',
      site: 'datadoghq.eu',
      service: 'ucs-frontend-nginx',
      env: stage,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: info.git.commit.id,
      allowedTracingUrls: [(url) => url.startsWith('https://ucs-sales-api-')],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0, // DO NOT ENABLE - GDPR NIGHTMARE - AND MONEY
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      // https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/?tab=maskuserinput
      // der Privacy Level kann auch HTML Item Ebene jeweil geändert werden
      defaultPrivacyLevel: 'mask-user-input',
      telemetrySampleRate: 0
    });

    datadogRum.startSessionReplayRecording();
  }

  public getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('edg/') > -1:
      return 'chromium based';
    case agent.indexOf('opr') > -1 && !!(<any>window).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      if (agent.indexOf('crios') > -1) {
        return 'chrome';
      }
      return 'safari';
    default:
      return 'other';
    }
  }

  loadMSClarityScript() {
    if(document.getElementById('ms-clarity-script')) {
      //Script already present
      return;
    }
    let node = document.createElement('script'); // creates the script tag
    // set the source (insert url in between quotes)
    node.id = 'ms-clarity-script';
    node.innerHTML = '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; ' +
      't=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; ' +
      'y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })' +
      '(window, document, "clarity", "script", "crjkzzx3db");';
    // set the script type
    node.type = 'text/javascript';
    // makes script run asynchronously
    node.async = true;
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  setStageInfo() {
    this.systemSettingsService
      .isSystemFeatureActivatedForAnyChannel('SHOW_STAGE_INFO')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showStageInfo => {
        if (showStageInfo) {
          if (window.location.hostname.includes('localhost')) {
            this.stage = 'local';
          } else if (window.location.hostname.includes('entw') || window.location.hostname.includes('dev')) {
            this.stage = 'dev';
          } else if (window.location.hostname.includes('qa')) {
            this.stage = 'qa';
          } else if (window.location.hostname.includes('demo')) {
            this.stage = 'demo';
          } else {
            this.stage = 'prod';
          }
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.disconnectWebsockets();
    }
  }

  close(alert: BarAlert) {
    this.store.dispatch(new DismissAlertAction());
  }

  private updateViewSettingsInStore() {
    // get view-settings from local storage and initially propagate to redux store
    let activeTags = <ViewTag[]>['MILEAGE', 'INITIAL-REGISTRATION', 'FUEL', 'POWER'];
    try {
      const items = localStorage.getItem('ucs-view-settings');
      if (items !== null) {
        activeTags = JSON.parse(items);
      }
    } catch (e) {
    }
    this.store.dispatch(new UpdateViewSettings(activeTags));
  }

  private updateUserLocal() {
    if (this.translateService.getBrowserLang() === 'de'
      || this.translateService.getBrowserLang() === 'de-de-key') {
      this.store.dispatch(new UpdateUserLocalAction(this.translateService.getBrowserLang()));
    } else {
      // default local 'en'
      this.store.dispatch(new UpdateUserLocalAction('en'));
    }
  }
}
