/**
 * LIMITATION:
 * For NPM setup, this feature flag singleton is shared between RUM and Logs product.
 * This means that an experimental flag set on the RUM product will be set on the Logs product.
 * So keep in mind that in certain configurations, your experimental feature flag may affect other products.
 *
 * FORMAT:
 * All feature flags should be snake_cased
 */
// We want to use a real enum (i.e. not a const enum) here, to be able to check whether an arbitrary
// string is an expected feature flag
// eslint-disable-next-line no-restricted-syntax
export var ExperimentalFeature;
(function (ExperimentalFeature) {
  ExperimentalFeature["WRITABLE_RESOURCE_GRAPHQL"] = "writable_resource_graphql";
  ExperimentalFeature["CUSTOM_VITALS"] = "custom_vitals";
  ExperimentalFeature["TOLERANT_RESOURCE_TIMINGS"] = "tolerant_resource_timings";
})(ExperimentalFeature || (ExperimentalFeature = {}));
var enabledExperimentalFeatures = new Set();
export function addExperimentalFeatures(enabledFeatures) {
  enabledFeatures.forEach(function (flag) {
    enabledExperimentalFeatures.add(flag);
  });
}
export function isExperimentalFeatureEnabled(featureName) {
  return enabledExperimentalFeatures.has(featureName);
}
export function resetExperimentalFeatures() {
  enabledExperimentalFeatures.clear();
}
export function getExperimentalFeatures() {
  return enabledExperimentalFeatures;
}
